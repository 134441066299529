/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('/src/assets/fonts/poppins/poppins.css');
@import url('/src/assets/fonts/aller/aller.css');
// D:\WorkSpace\salik\salik-liva-ui\src\assets\fonts\poppins\poppins.css

* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   list-style: none;
   // font-family: "poppins";
   font-family: "aller";
   user-select: none;
   scrollbar-width: thin;
}

:root {
  --line-border-color: #B2B2B2;
  --line-border-color-plan: #FFFFFF;
}



:root {
   --primary-color: #FF6B00 --logo-font-color:#2E2E2F
}

html {
      @media screen and (max-width:'410px') {
      font-size: .80rem;
      }

      @media screen and (min-width:'767px') {
         // font-size: .40rem;
         // font-size: .66rem;
         font-size: 80%;
      }
      @media screen and (min-width:'1024px') {

         // font-size: .53rem;
//          font-size: .66rem;
     }

      @media screen and (min-width:'1280px') {

         // font-size: .66rem;
      }

      @media screen and (min-width:'1535px') {

         // font-size: .80rem;
      }

      @media screen and (min-width:'1641px') {

         // font-size: .85rem;
         
      }

      @media screen and (min-width:'1920px') {

         font-size: 100%;
      }
   }

      .ngx-slider {
          margin: 0 !important;
          .ngx-slider-bar {
              background: white !important;
          }
        .ngx-slider-selection {
          background: #FF6900 !important;
        }
        .ngx-slider-pointer {
          background: transparent !important;
          height: 16px !important;
          width: 16px !important ;
    outline: none !important;
          &:after {
            background: #FF6900 !important ;
            width: 16px !important;
            height: 16px !important;
            top: 7px !important;
            left: 0px !important;
            border-radius: 100% !important;
          }
        }
  
      }
  
      .loader {
         position: relative;
         width: 54px;
         height: 54px;
         border-radius: 10px;
       }
       
       .loader div {
         width: 8%;
         height: 24%;
         background: rgb(128, 128, 128);
         position: absolute;
         left: 50%;
         top: 30%;
         opacity: 0;
         border-radius: 50px;
         box-shadow: 0 0 3px rgba(0,0,0,0.2);
         animation: fade458 1s linear infinite;
       }
       
       @keyframes fade458 {
         from {
           opacity: 1;
         }
       
         to {
           opacity: 0.25;
         }
       }
       
       .loader .bar1 {
         transform: rotate(0deg) translate(0, -130%);
         animation-delay: 0s;
       }
       
       .loader .bar2 {
         transform: rotate(30deg) translate(0, -130%);
         animation-delay: -1.1s;
       }
       
       .loader .bar3 {
         transform: rotate(60deg) translate(0, -130%);
         animation-delay: -1s;
       }
       
       .loader .bar4 {
         transform: rotate(90deg) translate(0, -130%);
         animation-delay: -0.9s;
       }
       
       .loader .bar5 {
         transform: rotate(120deg) translate(0, -130%);
         animation-delay: -0.8s;
       }
       
       .loader .bar6 {
         transform: rotate(150deg) translate(0, -130%);
         animation-delay: -0.7s;
       }
       
       .loader .bar7 {
         transform: rotate(180deg) translate(0, -130%);
         animation-delay: -0.6s;
       }
       
       .loader .bar8 {
         transform: rotate(210deg) translate(0, -130%);
         animation-delay: -0.5s;
       }
       
       .loader .bar9 {
         transform: rotate(240deg) translate(0, -130%);
         animation-delay: -0.4s;
       }
       
       .loader .bar10 {
         transform: rotate(270deg) translate(0, -130%);
         animation-delay: -0.3s;
       }
       
       .loader .bar11 {
         transform: rotate(300deg) translate(0, -130%);
         animation-delay: -0.2s;
       }
       
       .loader .bar12 {
         transform: rotate(330deg) translate(0, -130%);
         animation-delay: -0.1s;
       }
       