@font-face {
    font-family: "aller";
    font-weight: normal;
    font-size: normal;
    src: url('/assets/fonts/aller/Aller_Rg.ttf'),format('ttf');
}

@font-face {
    font-family: "aller";
    font-weight: 300;
    font-size: normal;
    src: url('/assets/fonts/aller/Aller_Lt.ttf'),format('ttf');
}


@font-face {
    font-family: "aller";
    font-weight: 700;
    font-size: normal;
    src: url('/assets/fonts/aller/Aller_Bd.ttf'),format('ttf');
}